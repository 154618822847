import React, { useState, useRef, useEffect, ReactNode } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import { useCookies } from 'react-cookie';
import { Analytics } from '@vercel/analytics/react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tooltip } from 'react-tooltip';

import Sidebar from "./components/Sidebar";
import AppHeader from "./components/AppHeader";
import BottomBar from "./components/BottomBar";
import LoadingSpinner from "./components/base/Spinner";
import PrivacyModal from "./components/PrivacyModal";
import TermsModal from "./components/TermsModal";

import Spacer from "./components/base/Spacer";
import Space from "./components/base/Space";
import Text from "./components/base/Text";
import "./LandingPage.css";
import ImageContainer from "./components/ImageContainer";
import AppFooter from "./components/AppFooter";
import MenuModal from "./components/MenuModal";
import Button from "./components/base/Button";
import MovingScroll from "./components/MovingScroll";
import Container from "./components/Container";
import EmployeeCard from "./components/EmployeeCard";
import QuickFeature from "./components/QuickFeature";
import CheckFeature from "./components/CheckFeature";
import Logo from "./components/Logo";

//             Measure your workflows in minutes, not hours or days


const integrationWords = ['Applied Epic', 'Vertafore AMS360', 'Gmail', 'Outlook'];

const Careers = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState<string>("overview");
  const [clientId, setClientId] = useState<string | null>(null);
  const [privacyModalOpen, setPrivacyModalOpen] = useState<boolean>(false);
  const [menuModalOpen, setMenuModalOpen] = useState<boolean>(false);
  const [termsModalOpen, setTermsModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<ReactNode | null>(null);
  const [successMessage, setSuccessMessage] = useState<ReactNode | null>(null);
  const [isEngineerOpen, setIsEngineerOpen] = useState<boolean>(false);
  const [isGrowthOpen, setIsGrowthOpen] = useState<boolean>(false);

  const [isPortrait, setIsPortrait] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [fadeIn, setFadeIn] = useState<boolean>(false);
  const [currentWordIndex, setCurrentWordIndex] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    // Set a delay before starting the fade-in animation
    const timer = setTimeout(() => {
      setFadeIn(true);
    }, 200); // 1-second delay

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);


  useEffect(() => {
    const checkOrientation = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };
    checkOrientation();
    window.addEventListener('resize', checkOrientation);
    return () => window.removeEventListener('resize', checkOrientation);
  }, []);

  const handleScroll = (e) => {
    const { scrollTop } = e.target;
    setScrollPosition(scrollTop);
    //console.log("SCROLL POSITION IS NOW ");
    //console.log(scrollTop);
  };

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, {
        position: "top-center",
        closeOnClick: true,
        autoClose: false,
        pauseOnHover: true,
        draggable: true,
      });
    }

  }, [errorMessage])

  useEffect(() => {
    if (successMessage) {
      toast.info(successMessage, {
        position: "top-center",
        closeOnClick: false,
        autoClose: false,
        pauseOnHover: true,
        draggable: true,
      });
    }

  }, [successMessage])

  return (
    <div className="landingPageBackground" onScroll={handleScroll}>
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        //position: "fixed",
        width: "calc(100% - 100px)",
        height: "100px",
        padding: "0px 50px",
        //top: 0,
        //left: 0,
        zIndex: 5,
      }}>
        <div style={{ transform: isPortrait ? "translateX(-20px)" : undefined }}>
          <Logo />
        </div>
        { isPortrait && (
          <img src={process.env.PUBLIC_URL + "/assets/menu.png"} style={{ width: "35px", height: "35px", cursor: "pointer" }} onClick={() => setMenuModalOpen(true)} /> 
        )}
        { !isPortrait && (
          <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "20px",
          }}>
            <Link to="/" style={{ textDecoration: 'none' }}><Text size={16} weight={600} onClick={() => document.getElementById('how-it-works')?.scroll({behavior: 'smooth'})}>
              How it works
            </Text></Link>
            <Link to="/careers" style={{ textDecoration: 'none' }}><Text size={16} weight={600} onClick={() => document.getElementById('how-it-works')?.scroll({behavior: 'smooth'})}>
              Careers
            </Text></Link>
            <Button color="#4455F3" width="150px" height="50px" onClick={() => window.open("https://calendly.com/matthewfastow/coverflow-demo", "_blank")}>
              <Text size={14} color="white">
                Request Demo
              </Text>
            </Button>
          </div>
        )}
      </div>
      <div style={{ 
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        margin: "0% 20%"
      }}>
        <Text size={40} weight={600} align="center">
          Open Roles
        </Text>
        <Space px={20} />
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          cursor: "pointer"
        }} onClick={() => setIsEngineerOpen(!isEngineerOpen)}>        
          <Text size={30} weight={600}>
            Founding Engineer
          </Text>
          <img src={process.env.PUBLIC_URL + "/assets/forward.png"} style={{ width: "35px", height: "35px", rotate: isEngineerOpen ? "90deg" : undefined }} /> 
        </div>
        { isEngineerOpen && (
          <>
            <Space px={5} />
            <Text size={20} weight={600}>
              Background:
            </Text>
            <Text size={16}>
              The insurance brokerage industry brings in $310 billion in revenue annually and profits over 1/5th of that. They are the middle man of the insurance industry - the modern insurance broker makes their money by connecting people that need insurance to those that provide it. Still, every year, billions are spent on other tasks - processing millions of pages of documents, inputting information into systems, and filing paper work - all needed in order to get clients the right insurance policy.
            </Text>
            <Space px={5} />
            <Text size={20} weight={600}>
              Who We Are:
            </Text>
            <Text size={16}>
              Coverflow is a seed stage AI company on a mission to eliminate these taxing workflows core to insurance agencies. Our platform currently processes hundreds of insurance policies daily for agents globally, runs over 6 figures in ARR, and is growing rapidly. We’re a young, energetic, and lean team that met at Princeton—since then, we’ve led eng teams at Roblox and Robust Intelligence. We’re backed by Afore Capital and are looking for a founding engineer to help expand our product and our run rate by 10x over the next 6 months.
            </Text>
            <Space px={5} />
            <Text size={16}>
              As a founding engineer you’ll:
            </Text>
            <ul style={{ color: "white" }}>
              <li>
                <Text size={16}>
                  Own and build features powered by a generative AI analysis engine
                </Text>
              </li>
              <li>
                <Text size={16}>
                  Iterate with customers to build an amazing product
                </Text>
              </li>
              <li>
                <Text size={16}>
                  Be a part of a strong, passionate, dedicated team
                </Text>
              </li>
            </ul>
            <Text size={16}>
              What we’re looking for:
            </Text>
            <ul style={{ color: "white" }}>
              <li>
                <Text size={16}>
                  2+ years experience in Python, React
                </Text>
              </li>
              <li>
                <Text size={16}>
                  Demonstrated experience and/or deep interest in building generative AI products
                </Text>
              </li>
              <li>
                <Text size={16}>
                  Experience shipping 0→1 products or features, and demonstrated ability to do rapid prototyping
                </Text>
              </li>
              <li>
                <Text size={16}>
                  Ability to move across the stack quickly while balancing quality
                </Text>
              </li>
              <li>
                <Text size={16}>
                  Humble, determined, willing to take on any tasks and learn like crazy
                </Text>
              </li>
              <li>
                <Text size={16}>
                  Enjoy working with customers and helping them succeed
                </Text>
              </li>
            </ul>
            <Text size={16}>
              To apply, submit your resume <a href="https://bcovf7hjq8v.typeform.com/to/kDLCmCD3">here</a>
            </Text>
          </>
        )}
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          cursor: "pointer"
        }} onClick={() => setIsGrowthOpen(!isGrowthOpen)}>        
          <Text size={30} weight={600}>
            Head of Growth
          </Text>
          <img src={process.env.PUBLIC_URL + "/assets/forward.png"} style={{ width: "35px", height: "35px", rotate: isGrowthOpen ? "90deg" : undefined }} /> 
        </div>
        { isGrowthOpen && (
          <>
            <Space px={5} />
            <Text size={20} weight={600}>
              Background:
            </Text>
            <Text size={16}>
              The insurance brokerage industry brings in $310 billion in revenue annually and profits over 1/5th of that. They are the middle man of the insurance industry - the modern insurance broker makes their money by connecting people that need insurance to those that provide it. Still, every year, billions are spent on other tasks - processing millions of pages of documents, inputting information into systems, and filing paper work - all needed in order to get clients the right insurance policy.
            </Text>
            <Space px={5} />
            <Text size={20} weight={600}>
              Who We Are:
            </Text>
            <Text size={16}>
              Coverflow is a seed stage AI company on a mission to eliminate these taxing workflows core to insurance agencies. Our platform currently processes hundreds of insurance policies daily for agents globally and is growing rapidly. We’re a young, energetic, and lean team that met at Princeton—since then, we’ve led teams at Roblox and Robust Intelligence. We’re backed by Afore Capital and are looking for a Head of Growth to feed our funnel and grow revenue by 10x over the next 6 months.
            </Text>
            <Space px={5} />
            <Text size={16}>
              As a founding marketer you’ll:
            </Text>
            <ul style={{ color: "white" }}>
              <li>
                <Text size={16}>
                  Own and develop a wide array of growth channels, both digital and physical
                </Text>
              </li>
              <li>
                <Text size={16}>
                  Help drive 10x growth by being primarily responsible for top-of-funnel
                </Text>
              </li>
              <li>
                <Text size={16}>
                  Participate in sales calls and assist with deal closure
                </Text>
              </li>
              <li>
                <Text size={16}>
                  Be a part of a strong, passionate, dedicated team
                </Text>
              </li>
            </ul>
            <Text size={16}>
              What we’re looking for:
            </Text>
            <ul style={{ color: "white" }}>
              <li>
                <Text size={16}>
                  Experience growing a startup from pre-seed/seed to series A and beyond
                </Text>
              </li>
              <li>
                <Text size={16}>
                  Metric- and hypothesis-driven thinkers who work to validate or invalidate ideas quickly
                </Text>
              </li>
              <li>
                <Text size={16}>
                  Humble, determined, willing to take on any tasks and learn like crazy
                </Text>
              </li>
              <li>
                <Text size={16}>
                  Enjoy working with customers and helping them succeed
                </Text>
              </li>
            </ul>
            <Text size={16}>
              To apply, submit your resume <a href="https://bcovf7hjq8v.typeform.com/to/kDLCmCD3">here</a>
            </Text>
          </>
        )}
      </div>
      <Space px={400} />
      
      <AppFooter isPortrait={isPortrait} />

      <TermsModal setModalOpen={setTermsModalOpen} modalOpen={termsModalOpen} />
      <PrivacyModal setModalOpen={setPrivacyModalOpen} modalOpen={privacyModalOpen} />
      <MenuModal setModalOpen={setMenuModalOpen} modalOpen={menuModalOpen} />
      <ToastContainer />
      <Analytics />
    </div>
  )
};

export default Careers;
